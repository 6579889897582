import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import Sidebar from "../../components/Sidebar";
import { getTeams } from "../../services/teams/getTeams";
import DeleteModal from "../../utils/DeleteModal";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import { deleteTeam } from "../../services/teams/deleteTeam";

const TeamsListPage = () => {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [teams, setTeams] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState(null);

    useEffect(() => {
        const fetchTeams = async () => {
            const response = await getTeams();
            if (response.data.success) {
                setTeams(response.data.teams);
                setLoading(false);
                console.log(response.data.teams);
            } else {
                console.log(response);
            }
        };
        fetchTeams();
    }, []);

    const onClickDeleteTeam = (team) => {
        setTeamToDelete(team);
        setIsDeleteModalOpen(true);
    };

    const removeTeam = async () => {
        const response = await deleteTeam(teamToDelete.id);
        if (response.data.success) {
            const newTeams = teams.filter((team) => team.id !== teamToDelete.id);
            setTeams(newTeams);
            toast.success("Team deleted successfully");
        } else {
            toast.error(response.data.message);
        }
        setIsDeleteModalOpen(false);
    };

    return (
        <div className="flex h-screen overflow-hidden">
            <Toaster />
            <DeleteModal
                isModalOpen={isDeleteModalOpen}
                setIsModalOpen={setIsDeleteModalOpen}
                onRemove={removeTeam}
                title={
                    teamToDelete &&
                    `Are you sure you want to delete the team ${teamToDelete.name}`
                }
            />
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                {!loading ? (
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {/* Page header */}
                            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                        Teams
                                    </h1>
                                </div>

                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end items-end gap-2">
                                    <button
                                        onClick={() => navigate("/teams/create")}
                                        className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                                    >
                                        <svg
                                            className="w-4 h-4 fill-current opacity-50 shrink-0"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                        </svg>
                                        <span className="xs:block ml-2">
                                            Add Team
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <FirmFlowTable
                                columns={[
                                    {
                                        Header: "Name",
                                        accessor: "name",
                                        Cell: (props) => props.value,
                                    },
                                    {
                                        Header: "Department",
                                        accessor: "departmentName",
                                        Cell: (props) => props.value,
                                    },
                                    {
                                        Header: "Team Members",
                                        accessor: "members",
                                        Cell: (props) =>
                                            props.value.map((member) => (
                                                <ul
                                                    key={member.id}
                                                    className="list-disc"
                                                >
                                                    <li>
                                                        {member.name} - {member.role}
                                                    </li>
                                                </ul>
                                            )),
                                    },
                                    {
                                        Header: "Actions",
                                        accessor: "id",
                                        Cell: (props) => (
                                            <>
                                                <>
                                                    <button
                                                        data-tip="Edit"
                                                        onClick={() =>
                                                            navigate(
                                                                `/teams/${props.value}`
                                                            )
                                                        }
                                                        className="btn bg-teal-500 hover:bg-teal-600 text-white h-10 mx-2 w-20"
                                                    >
                                                        Edit
                                                    </button>

                                                    <button
                                                        data-tip="Delete"
                                                        onClick={() =>
                                                            onClickDeleteTeam({
                                                                id: props.value,
                                                                name: props.row
                                                                    .original.name,
                                                            })
                                                        }
                                                        className="btn bg-red-500 hover:bg-red-600 text-white h-10 mx-2 w-20"
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            </>
                                        ),
                                    },
                                ]}
                                data={teams}
                            />
                        </div>
                    </main>
                ) : (
                    <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                        <motion.div
                            className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                            initial={{ rotateZ: 0 }}
                            animate={{ rotateZ: 360 }}
                            exit={{ rotateZ: 0 }}
                            transition={{
                                repeat: Infinity,
                                ease: "linear",
                                duration: 1,
                            }}
                        ></motion.div>
                    </div>
                )}
            </div>
        </div>
    );
};
export default TeamsListPage;
