const InputTextModal = ({
    label,
    name,
    onChange,
    value,
    required,
    type,
    disabled,
    minlenght,
    maxlenght,
}) => {
    return (
        <div className="mt-5 sm:mt-6">
            <label
                htmlFor={name}
                className="block text-sm font-medium text-left text-gray-700"
            >
                {label}
            </label>
            <div className="relative inline-block w-full text-left">
                <input
                    type={type}
                    required={required}
                    name={name}
                    onChange={onChange}
                    value={value}
                    className="inline-flex justify-between w-full px-3 py-2 text-sm font-normal text-gray-700 truncate bg-white border border-gray-200 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                    disabled={disabled}
                    min={minlenght}
                    maxLength={maxlenght}
                />
            </div>
        </div>
    );
};

export default InputTextModal;
