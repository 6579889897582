import axios from "axios";
import Cookies from "js-cookie";
import { FIRMFLOWURL } from "../url";

export const deleteClientServiceSubscriptionTaskChecklistItem = (id) => {
    return new Promise(async (resolve) => {
        try {
            const token = Cookies.get("Bearer");
            const response = await axios.delete(
                `${FIRMFLOWURL}api/ClientServiceSubscriptionTaskChecklistItem/${id}`,
                {
                    headers: { Authorization: token },
                }
            );
            console.log("respuesta: ", response);
            resolve({ ...response, success: true });
        } catch (err) {
            console.log("error on creating department", err);
            if (err.message.includes(401)) {
                Cookies.remove("Bearer");
                window.location.replace("/");
                return;
            }
            resolve({ success: false, message: err.message });
        }
    });
};
