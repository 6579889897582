import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Header from "../../components/Header";
import DialogConfirmationForRemovingClients from "../../components/Modals/DialogConfirmationForRemovingClients";
import FirmFlowTable from "../../components/mytasks/FirmFlowTable";
import Sidebar from "../../components/Sidebar";
import { FIRMFLOW_NAMES } from "../../helpers/firmFlowNames";
import { formatTaxNumber } from "../../helpers/formatTaxNumber";
import { deleteClient } from "../../services/deleteClient";
import { getClients } from "../../services/getClients";

function Clients() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tooltip, showTooltip] = useState(true);
    const [showDialog, setShowDialog] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});
    //const [clientSubscriptions, setClientSubscriptions] = useState(null);
    //const [loggedInEmployee, setLoggedInEmployee] = useState(null);
    const navigate = useNavigate();

    const callOnLoad = async () => {
        const clients = await getClients();
        console.log(clients);
        let list = [];
        // eslint-disable-next-line
        clients.data.map((client, i) => {
            list.push({ ...client, index: i, checked: false });
        });
        setClients(list);
        setLoading(false);
    };

    useEffect(() => {
        callOnLoad();
        //getNewLoggedInEmployeeFunction(setLoggedInEmployee);
    }, []);

    const removeClient = async (client) => {
        /*   if (loggedInEmployee.role === 0) {
      toast.error("You don't have permission to do this.");
      return;
    } */
        console.log("deleting client...", client.clientName);
        const res = await deleteClient(client.id);
        console.log(res);
        if (res.success) {
            toast.success("Client deleted successfully");
            callOnLoad();
        } else {
            toast.error(res.message);
        }
    };

    /* const fetchClientSubscriptions = async (clientId) => {
        const res = await getClientSubscriptionsByClientId(clientId);
        console.log(res);
        if (res.success) {
            setClientSubscriptions(res.data.clientServiceSubscriptions);
        } else {
            toast.error(res.message);
        }
    }; */

    const onClickDelete = (client) => {
        //fetchClientSubscriptions(client.id);
        setSelectedClient(client);
        setShowDialog(true);
    };

    const columns = useMemo(
        () => [
            {
                Header: "Client",
                accessor: "clientName",
                Cell: (props) => (
                    <>
                        {tooltip && (
                            <ReactTooltip
                                type="light"
                                effect="solid"
                                place="top"
                                border={true}
                                borderColor={"rgb(220,220,220)"}
                                className="bg-gray-50 rounded-md"
                                multiline={false}
                            />
                        )}
                        <button
                            onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => showTooltip(false)}
                            data-tip={`${FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTIONS} for ${props.row.original.clientName}`}
                            onClick={() =>
                                navigate(`/clients/manage/${props.row.original.id}`)
                            }
                            className="text-gray hover:text-blue-500 rounded-full"
                        >
                            {props.row.original.clientName}
                        </button>
                    </>
                ),
            },
            {
                Header: "Tax ID Number",
                accessor: "taxNumber",
                Cell: (props) => (
                    <a
                        className="text-gray hover:text-blue-500 rounded-full"
                        href={`/clients/manage/${props.row.original.id}`}
                    >
                        {formatTaxNumber({
                            clientType: props.row.original.clientDetail.clientType,
                            taxNumber: props.value,
                        })}
                    </a>
                ),
            },
            {
                Header: "Phone",
                accessor: "phoneNumber",
                Cell: (props) => (
                    <a
                        className="text-gray hover:text-blue-500 rounded-full"
                        href={`/clients/manage/${props.row.original.id}`}
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Type",
                accessor: "clientDetail.clientType",
                Cell: (props) => (
                    <a
                        className="text-gray hover:text-blue-500 rounded-full"
                        href={`/clients/manage/${props.row.original.id}`}
                    >
                        {props.value}
                    </a>
                ),
            },
            {
                Header: "Email",
                accessor: "clientDetail.clientEmail",
                Cell: (props) => (
                    <a
                        className="text-gray hover:text-blue-500 rounded-full"
                        href={`/clients/manage/${props.row.original.id}`}
                    >
                        {props.value}
                    </a>
                ),
            },
            /*  {
                Header: "Is Meeting Required?",
                accessor: "clientDetail.isMeetingRequired",
                Cell: (props) => (
                    <a
                        className="text-gray hover:text-blue-500 rounded-full"
                        href={`/clients/manage/${props.row.original.id}`}
                    >
                        {props.value ? "Yes" : "No"}
                    </a>
                ),
            }, */
            {
                Header: "Actions",
                accessor: "id",
                Cell: (props) => (
                    <>
                        {tooltip && (
                            <ReactTooltip
                                type="light"
                                effect="solid"
                                place="top"
                                border={true}
                                borderColor={"rgb(220,220,220)"}
                                className="bg-gray-50 rounded-md"
                                multiline={false}
                            />
                        )}
                        <button
                            onMouseEnter={() => showTooltip(true)}
                            onMouseLeave={() => showTooltip(false)}
                            data-tip={`${FIRMFLOW_NAMES.CLIENT_SERVICE_SUBSCRIPTIONS} for ${props.row.original.clientName}`}
                            onClick={() =>
                                navigate(`/clients/manage/${props.value}`)
                            }
                            className="text-slate-400 hover:text-slate-500 rounded-full mx-4"
                        >
                            <span className="sr-only">Menu</span>
                            <svg
                                className="w-8 h-8 fill-current"
                                viewBox="0 0 32 32"
                            >
                                <circle cx="16" cy="16" r="2" />
                                <circle cx="10" cy="16" r="2" />
                                <circle cx="22" cy="16" r="2" />
                            </svg>
                        </button>
                        <button
                            data-tip="Edit Basic Info"
                            onClick={() => navigate(`/clients/${props.value}`)}
                            className="text-slate-400 hover:text-slate-500 rounded-full mx-4"
                        >
                            <span className="sr-only">Menu</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                />
                            </svg>
                        </button>
                        <button
                            data-tip="Delete"
                            onClick={() => onClickDelete(props.row.original)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-7 h-7 text-slate-400 hover:text-red-500 p-1 pt-0 mx-4"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                    </>
                ),
            },
        ],
        []
    );
    // const handleSelectedItems = (selectedItems) => {};

    return (
        <div className="flex h-screen overflow-hidden">
            <Toaster />
            {/* Modal */}
            {/* <AddSubscriptionModal
          open={subscriptionModalOpen}
          setOpen={setSubscriptionModalOpen}
          services={services}
          setServices={setServices}
          clients={clientsForSubscription}
          refreshSubs={() => fetchClientSubscriptions()}
        /> */}
            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <DialogConfirmationForRemovingClients
                    showDialog={showDialog}
                    onCancel={() => {
                        setShowDialog(false);
                        setSelectedClient({});
                    }}
                    onConfirm={() => {
                        removeClient(selectedClient);
                        setShowDialog(false);
                    }}
                    title="Delete Client"
                    description={`Click confirm to delete ${selectedClient.clientName}`}
                    clientSubscriptions={null}
                />
                {!loading ? (
                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            {/* Page header */}
                            <div className="sm:flex sm:justify-between sm:items-center mb-8">
                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                        Clients
                                    </h1>
                                </div>

                                {/* Right: Actions */}
                                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                                    {/* Add customer button */}
                                    <button
                                        onClick={() => navigate("/clients/create")}
                                        className="h-10 btn bg-teal-500 hover:bg-teal-600 text-white"
                                    >
                                        <svg
                                            className="w-4 h-4 fill-current opacity-50 shrink-0"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                        </svg>
                                        <span className="xs:block ml-2">
                                            Add Client
                                        </span>
                                    </button>
                                    {/* subscribe button */}
                                    {/* <button
                     onClick={() => setSubscriptionModalOpen(true)}
                      className="btn bg-teal-500 hover:bg-teal-600 text-white h-10"
                    >
                      Subscribe Service to Client
                    </button> */}
                                </div>
                            </div>

                            {/* Table */}
                            <FirmFlowTable data={clients} columns={columns} />
                        </div>
                    </main>
                ) : (
                    <div className="w-full mt-64 flex justify-center items-center max-w-9xl">
                        <motion.div
                            className="border border-t-gray-700 border-t-[4px] rounded-full border-gray-200 w-12 h-12"
                            initial={{ rotateZ: 0 }}
                            animate={{ rotateZ: 360 }}
                            exit={{ rotateZ: 0 }}
                            transition={{
                                repeat: Infinity,
                                ease: "linear",
                                duration: 1,
                            }}
                        ></motion.div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Clients;
