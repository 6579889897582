import { parseDigit, templateFormatter, templateParser } from "input-format";
import ReactInput from "input-format/react";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { TAX_NUMBER_REGEX } from "../../helpers/taxNumberRegex";
import { createClient } from "../../services/createClient";
import { getTeams } from "../../services/teams/getTeams";
import AssignTeamsListBox from "../Teams/AssignTeamsListBox";

function CreateClientForm() {
    const navigate = useNavigate();
    const [teams, setTeams] = useState([]);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [form, setForm] = useState({
        clientName: "",
        phoneNumber: "",
        taxNumber: "",
        teams: [],
        address: {
            street: "",
            city: "",
            stateProvince: "",
            postalCode: "",
            country: "United States",
        },
        clientDetail: {
            clientType: "individual",
            spouseName: "",
            spouseEmail: "",
            spouseTaxNumber: "",
            pointOfContactName: "",
            pointOfContactEmail: "",
            secondaryPointOfContactName: "",
            secondaryPointOfContactEmail: "",
            clientEmail: "",
            language: "English",
            contactMethod: "Email",
        },
    });

    useEffect(() => {
        const fetchTeams = async () => {
            const response = await getTeams();
            if (response.data.success) {
                setTeams(response.data.teams);
                console.log(response.data.teams);
            } else {
                console.log(response.data.message);
            }
        };
        fetchTeams();
    }, []);

    async function create() {
        if (form.clientName === "") {
            toast.error("Name is required");
            return;
        }

        if (form.clientDetail.clientEmail === "") {
            toast.error("Email is required");
            return;
        }

        if (!TAX_NUMBER_REGEX.test(form.taxNumber)) {
            toast.error("Tax ID Number has an invalid format");
            return;
        }

        if (form.teams.length === 0) {
            toast.error("At least one team is required");
            return;
        }

        var props = Object.keys(form);

        if (form.clientType == "individual") {
            props = props.filter(
                (prop) =>
                    prop != "pointOfContactName" &&
                    prop != "pointOfContactEmail" &&
                    prop != "secondaryPointOfContactName" &&
                    prop != "secondaryPointOfContactEmail"
            );
        } else {
            props = props.filter(
                (prop) =>
                    prop != "spouseEmail" &&
                    prop != "spouseName" &&
                    prop != "spouseTaxNumber"
            );
        }

        let empty = false;
        console.log(props);
        if (!empty) {
            console.log(form);
            const service = await createClient(form);
            console.log(service);
            if (service.success) {
                toast.success(service.message);
                navigate("/clients");
            } else {
                toast.error(service.message);
            }
        } else {
            toast.error("Please fill out all details.");
        }
    }

    const onChangeTeams = (e) => {
        setForm({ ...form, teams: e });
    };

    return (
        <section>
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start max-w-2xl">
                                <Breadcrumbs
                                    home="/clients"
                                    pages={[
                                        {
                                            name: "Create client",
                                            href: `/clients/create`,
                                            current: true,
                                        },
                                    ]}
                                />

                                {/* Page header */}
                                <div className="sm:flex sm:justify-between sm:items-center mb-8 w-full mt-12">
                                    {/* Left: Title */}
                                    <div className="mb-4 sm:mb-0">
                                        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                                            Create client
                                        </h1>
                                    </div>
                                </div>

                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full">
                                    <header className="px-5 py-4 border-b border-slate-200">
                                        <h2 className="font-semibold text-slate-800">
                                            Details
                                        </h2>
                                    </header>

                                    <div className="space-y-5 px-5 py-4">
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Name *
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            clientName:
                                                                e.target.value,
                                                        })
                                                    }
                                                    type="text"
                                                    value={form.title}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Email *
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            clientDetail: {
                                                                ...form.clientDetail,
                                                                clientEmail:
                                                                    e.target.value,
                                                            },
                                                        })
                                                    }
                                                    type="text"
                                                    value={
                                                        form.clientDetail
                                                            ?.clientEmail
                                                    }
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Phone number
                                            </label>
                                            <div className="mt-1">
                                                <ReactInput
                                                    value={form.phoneNumber}
                                                    onChange={(value) =>
                                                        setForm({
                                                            ...form,
                                                            phoneNumber: value,
                                                        })
                                                    }
                                                    format={templateFormatter(
                                                        "(xxx) xxx-xxxx"
                                                    )}
                                                    parse={templateParser(
                                                        "(xxx) xxx-xxxx",
                                                        parseDigit
                                                    )}
                                                    displayType="input"
                                                    type="text"
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <label
                                                htmlFor="taxNumber"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Tax ID Number *
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            taxNumber:
                                                                e.target.value,
                                                        })
                                                    }
                                                    placeholder="Insert only numbers"
                                                    type="number"
                                                    pattern="[0-9]{8}"
                                                    value={form.description}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                />
                                            </div>
                                        </div>
                                        <div className="pb-1">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Client type
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            clientDetail: {
                                                                ...form.clientDetail,
                                                                clientType:
                                                                    e.target.value,
                                                            },
                                                        })
                                                    }
                                                    value={form.description}
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                >
                                                    <option value="individual">
                                                        Individual
                                                    </option>
                                                    <option value="business">
                                                        Business
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="pb-1">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Language
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            clientDetail: {
                                                                ...form.clientDetail,
                                                                language:
                                                                    e.target.value,
                                                            },
                                                        })
                                                    }
                                                    value={
                                                        form.clientDetail.language
                                                    }
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                >
                                                    <option value="English">
                                                        English
                                                    </option>
                                                    <option value="Spanish">
                                                        Spanish
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="pb-1">
                                            <label
                                                htmlFor="contactMethod"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Preferred method of contact
                                            </label>
                                            <div className="mt-1">
                                                <select
                                                    onChange={(e) =>
                                                        setForm({
                                                            ...form,
                                                            clientDetail: {
                                                                ...form.clientDetail,
                                                                contactMethod:
                                                                    e.target.value,
                                                            },
                                                        })
                                                    }
                                                    value={
                                                        form.clientDetail
                                                            .contactMethod
                                                    }
                                                    className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                >
                                                    <option value="Email">
                                                        Email
                                                    </option>
                                                    <option value="Text">
                                                        Text
                                                    </option>
                                                    <option value="Phone Call">
                                                        Phone Call
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <AssignTeamsListBox
                                            title={"Assigned teams"}
                                            teams={teams}
                                            assignedTeams={form.teams}
                                            onChange={onChangeTeams}
                                        />
                                    </div>
                                </div>
                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full mt-6">
                                    <header className="px-5 py-4 border-b border-slate-200">
                                        <h2 className="font-semibold text-slate-800 capitalize">
                                            {form.clientDetail?.clientType ==
                                            "individual"
                                                ? "Spouse"
                                                : "Business"}
                                        </h2>
                                    </header>

                                    {form.clientDetail?.clientType ==
                                    "individual" ? (
                                        <div>
                                            <div className="px-5 mt-5">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {`Spouse Name`}
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(e) =>
                                                            setForm({
                                                                ...form,
                                                                clientDetail: {
                                                                    ...form.clientDetail,
                                                                    spouseName:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        type="text"
                                                        value={
                                                            form.clientDetail
                                                                ?.spouseName
                                                        }
                                                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-5 mt-5">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Spouse Tax ID Number
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(e) =>
                                                            setForm({
                                                                ...form,
                                                                clientDetail: {
                                                                    ...form.clientDetail,
                                                                    spouseTaxNumber:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        type="text"
                                                        value={
                                                            form.clientDetail
                                                                ?.spouseTaxNumber
                                                        }
                                                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-5 mt-5 pb-5">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Spouse Email
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(e) =>
                                                            setForm({
                                                                ...form,
                                                                clientDetail: {
                                                                    ...form.clientDetail,
                                                                    spouseEmail:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        type="text"
                                                        value={
                                                            form.clientDetail
                                                                ?.spouseEmail
                                                        }
                                                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="px-5 mt-5">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Main point of contact
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(e) =>
                                                            setForm({
                                                                ...form,
                                                                clientDetail: {
                                                                    ...form.clientDetail,
                                                                    pointOfContactName:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        type="text"
                                                        value={
                                                            form.clientDetail
                                                                ?.pointOfContactName
                                                        }
                                                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-5 mt-5 pb-5">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email of main point of contact
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(e) =>
                                                            setForm({
                                                                ...form,
                                                                clientDetail: {
                                                                    ...form.clientDetail,
                                                                    pointOfContactEmail:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        type="text"
                                                        value={
                                                            form.clientDetail
                                                                ?.pointOfContactEmail
                                                        }
                                                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-5 mt-5">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Name of secondary point of
                                                    contact
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(e) =>
                                                            setForm({
                                                                ...form,
                                                                clientDetail: {
                                                                    ...form.clientDetail,
                                                                    secondaryPointOfContactName:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        type="text"
                                                        value={
                                                            form.clientDetail
                                                                ?.secondaryPointOfContactName
                                                        }
                                                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                            <div className="px-5 mt-5 pb-5">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email of secondary point of
                                                    contact
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        onChange={(e) =>
                                                            setForm({
                                                                ...form,
                                                                clientDetail: {
                                                                    ...form.clientDetail,
                                                                    secondaryPointOfContactEmail:
                                                                        e.target
                                                                            .value,
                                                                },
                                                            })
                                                        }
                                                        type="text"
                                                        value={
                                                            form.clientDetail
                                                                ?.secondaryPointOfContactEmail
                                                        }
                                                        className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full mt-6">
                                    <header className="px-5 py-4 border-b border-slate-200">
                                        <h2 className="font-semibold text-slate-800">
                                            Address
                                        </h2>
                                    </header>

                                    <div className="px-5 mt-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Street address
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        address: {
                                                            ...form.address,
                                                            street: e.target.value,
                                                        },
                                                    })
                                                }
                                                type="text"
                                                value={form.address.street}
                                                className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                    <div className="px-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            City
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        address: {
                                                            ...form.address,
                                                            city: e.target.value,
                                                        },
                                                    })
                                                }
                                                type="text"
                                                value={form.address.city}
                                                className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                    <div className="px-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            State/Province
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        address: {
                                                            ...form.address,
                                                            stateProvince:
                                                                e.target.value,
                                                        },
                                                    })
                                                }
                                                type="text"
                                                value={form.address.stateProvince}
                                                className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                    <div className="px-5">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Postal Code
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        address: {
                                                            ...form.address,
                                                            postalCode:
                                                                e.target.value,
                                                        },
                                                    })
                                                }
                                                type="text"
                                                value={form.address.postalCode}
                                                className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                            />
                                        </div>
                                    </div>
                                    <div className="px-5 pb-3">
                                        <label
                                            htmlFor="email"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Country
                                        </label>
                                        <div className="mt-1 mb-2">
                                            <select
                                                onChange={(e) =>
                                                    setForm({
                                                        ...form,
                                                        address: {
                                                            ...form.address,
                                                            country: e.target.value,
                                                        },
                                                    })
                                                }
                                                value={form.address.country}
                                                className="shadow-sm border border-slate-200 rounded-sm p-1 px-2 w-full max-w-sm focus:ring-0 focus:outline-none"
                                            >
                                                <option value="United States">
                                                    United States
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full flex justify-end">
                                    <button
                                        onClick={() => create()}
                                        type="submit"
                                        className="z-50 mt-4 ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                    >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
}
export default CreateClientForm;
