import { useMemo } from "react";
import FirmFlowTable from "../../../../components/mytasks/FirmFlowTable"; // Adjust the import path as necessary

const EmployeeWorkloadTable = ({ data }) => {
    const columns = useMemo(
        () => [
            {
                Header: "Employee",
                accessor: "employeeName",
                Cell: (props) => props.value,
            },
            {
                Header: "Total Clients",
                accessor: "totalClients",
                Cell: (props) => props.value,
            },
        ],
        []
    );
    return (
        <div>
            <FirmFlowTable columns={columns} data={data} />
        </div>
    );
};

export default EmployeeWorkloadTable;
