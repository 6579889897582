import { PlusIcon } from "@heroicons/react/24/solid";
import { useEffect, useReducer, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Breadcrumbs from "../../components/Breadcrumbs";
import InputTextModal from "../../components/clientServiceSubscriptionTask/modalGeneralComponents/InputTextModal";
import AssignedByListBox from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/AssignedByListBox";
import Header from "../../components/Header";
import ListBoxComponent from "../../components/ListboxComponent";
import Sidebar from "../../components/Sidebar";
import { getEmployees } from "../../services/getEmployees";
import { getServiceDepartments } from "../../services/service/getServiceDepartments";
import { addTeam } from "../../services/teams/addTeam";
import DepartmentListbox from "../TaskForm/DepartmentListBox";

const EMPLOYEE_ROLES = ["Manager", "Senior", "Staff"];

const TeamsCreatePage = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selectedRole, setSelectedRole] = useState(EMPLOYEE_ROLES[0]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [, setSelectedDepartment] = useState({});
    const [, setValidationErrors] = useState([]);

    const initialState = {
        name: "",
        department: "",
        members: [],
    };

    const teamReducer = (state, action) => {
        switch (action.type) {
            case "SET_NAME":
                return { ...state, name: action.payload };
            case "SET_DEPARTMENT":
                return { ...state, department: action.payload };
            case "ADD_MEMBER":
                if (
                    state.members.some(
                        (member) => member.employeeId === action.payload.employeeId
                    )
                ) {
                    return state;
                } else {
                    return { ...state, members: [...state.members, action.payload] };
                }
            case "REMOVE_MEMBER":
                return {
                    ...state,
                    members: state.members.filter(
                        (member) => member !== action.payload
                    ),
                };
            case "RESET_FORM":
                return initialState;
            default:
                throw new Error(`Unhandled action type: ${action.type}`);
        }
    };

    const [state, dispatch] = useReducer(teamReducer, initialState);

    const validateForm = () => {
        const newErrors = [];
        if (!state.name) {
            newErrors.push("Team name is required.");
        }

        if (!state.department) {
            newErrors.push("Department is required.");
        }

        if (state.members.length === 0) {
            newErrors.push("At least one member is required.");
        }

        setValidationErrors(newErrors);
        if (newErrors.length > 0) {
            newErrors.forEach((error) => toast.error(error));
        }
        return newErrors.length === 0;
    };

    useEffect(() => {
        const getNewEmployees = async () => {
            const response = await getEmployees();
            setEmployees(response.data.employees);
        };
        getNewEmployees();
        //getNewLoggedInEmployee();
    }, []);

    useEffect(() => {
        const getNewDepartments = async () => {
            const response = await getServiceDepartments();
            if (response.success) {
                setDepartments(response.data.departments);
                setSelectedDepartment(response.data.departments[0]);
            } else {
                toast.error(response.data.message);
            }
        };
        getNewDepartments();
    }, []);

    const onChangeSelectedRole = (e) => {
        setSelectedRole(e);
    };

    const onAddTeamMember = () => {
        if (!selectedEmployee || !selectedRole) {
            toast.error("Please select a team member and role.");
            return;
        }
        const newMember = { ...selectedEmployee, role: selectedRole };
        dispatch({ type: "ADD_MEMBER", payload: newMember });
        setSelectedEmployee(null);
        setSelectedRole(EMPLOYEE_ROLES[0]);
    };

    const onSave = async () => {
        if (validateForm()) {
            console.log("Form data:", state);

            const response = await addTeam(state);
            console.log(response);

            if (response.data.success) {
                toast.success("Team created successfully.");
                dispatch({ type: "RESET_FORM" });
            } else {
                toast.error(response.data.message);
            }

            setValidationErrors([]);
        }
    };

    return (
        <section className="flex flex-col w-full">
            <Toaster />
            <div className="flex h-screen overflow-hidden">
                {/* Sidebar */}
                <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                {/* Content area */}
                <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                    {/*  Site header */}
                    <Header
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                    />

                    <main>
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="flex flex-col items-start max-w-2xl">
                                <Breadcrumbs
                                    home="/teams"
                                    pages={[
                                        {
                                            name: "Teams",
                                            href: `/teams`,
                                            current: true,
                                        },
                                    ]}
                                />

                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold p-4">
                                    Create Team
                                </h1>
                                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative w-full pb-4">
                                    <div className="space-y-5 px-5 py-4">
                                        <InputTextModal
                                            required={true}
                                            label="Team name *"
                                            name="name"
                                            onChange={(e) =>
                                                dispatch({
                                                    type: "SET_NAME",
                                                    payload: e.target.value,
                                                })
                                            }
                                            value={state.name}
                                        />

                                        <DepartmentListbox
                                            label={"Department *"}
                                            departments={departments}
                                            selectedDepartment={state.department}
                                            onChangeDepartment={(e) =>
                                                dispatch({
                                                    type: "SET_DEPARTMENT",
                                                    payload: e,
                                                })
                                            }
                                        />

                                        {state.members.length > 0 ? (
                                            <div>
                                                <p className="text-sm font-medium text-gray-700 py-4">
                                                    Team members list
                                                </p>

                                                <div className="overflow-x-auto">
                                                    <table className="min-w-full bg-white">
                                                        <thead>
                                                            <tr>
                                                                <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium text-gray-700">
                                                                    Name
                                                                </th>
                                                                <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium text-gray-700">
                                                                    Email
                                                                </th>
                                                                <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium text-gray-700">
                                                                    Role
                                                                </th>
                                                                <th className="py-2 px-4 border-b border-gray-200 text-left text-sm font-medium text-gray-700">
                                                                    Actions
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {state.members.map(
                                                                (member, index) => (
                                                                    <tr key={index}>
                                                                        <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                                            {
                                                                                member.firstName
                                                                            }{" "}
                                                                            {
                                                                                member.lastName
                                                                            }
                                                                        </td>
                                                                        <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                                            {
                                                                                member.email
                                                                            }
                                                                        </td>
                                                                        <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                                            {
                                                                                member.role
                                                                            }
                                                                        </td>
                                                                        <td className="py-2 px-4 border-b border-gray-200 text-sm text-gray-700">
                                                                            <button
                                                                                onClick={() =>
                                                                                    dispatch(
                                                                                        {
                                                                                            type: "REMOVE_MEMBER",
                                                                                            payload:
                                                                                                member,
                                                                                        }
                                                                                    )
                                                                                }
                                                                                className="text-red-500 hover:text-red-700"
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ) : (
                                            <p className="text-red-500 text-medium font-medium py-4">
                                                Please add at least one team member.
                                            </p>
                                        )}

                                        <div className="flex w-full">
                                            <div className="w-3/4 pr-4">
                                                <AssignedByListBox
                                                    label="Team member *"
                                                    employees={employees}
                                                    assignedBy={selectedEmployee}
                                                    onChange={(e) =>
                                                        setSelectedEmployee(e)
                                                    }
                                                />
                                            </div>
                                            <div className="w-1/4">
                                                <ListBoxComponent
                                                    title="Role"
                                                    items={EMPLOYEE_ROLES}
                                                    selectedItem={selectedRole}
                                                    setSelectedItem={
                                                        onChangeSelectedRole
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full flex justify-end">
                                            <button
                                                onClick={onAddTeamMember}
                                                className="z-50 mt-2 inline-flex justify-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                            >
                                                <PlusIcon className="h-5 w-5 mr-2" />
                                                Team member
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full flex justify-end">
                                    <button
                                        onClick={onSave}
                                        className="z-50 mt-8 inline-flex justify-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-sm text-white bg-teal-500 hover:bg-teal-600 focus:outline-none focus:ring-0"
                                    >
                                        Save Team
                                    </button>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    );
};
export default TeamsCreatePage;
