/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { parseDigit, templateFormatter, templateParser } from "input-format";
import ReactInput from "input-format/react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import InputTextModal from "../../components/clientServiceSubscriptionTask/modalGeneralComponents/InputTextModal";
import ButtonModal from "../../components/clientServiceSubscriptionTask/updateClientServiceSubscriptionTaskModal/ButtonModal";
import { TAX_NUMBER_REGEX } from "../../helpers/taxNumberRegex";
import { getTeams } from "../../services/teams/getTeams";
import { updateClient } from "../../services/updateClient";
import SelectInModal from "../../utils/SelectInModal";
import AssignTeamsListBox from "../Teams/AssignTeamsListBox";

const METHODS_OF_CONTACT = ["Email", "Text", "Phone Call"];
const LANGUAGES = ["English", "Spanish"];
const CLIENT_TYPES = ["INDIVIDUAL", "BUSINESS"];

const UpdateClientModal = ({ open, setOpen, client, setClient }) => {
    const [clientForm, setClientForm] = useState(null);
    const [teams, setTeams] = useState([]);
    const navigate = useNavigate();

    let completeButtonRef = useRef(null);

    useEffect(() => {
        if (client == null) return;
        const newClient = {
            ...client,
            clientDetail: { ...client.clientDetail },
            address: { ...client.address },
            teams: [...client.teams],
            taxNumber: formatTaxId(client.taxNumber),
        };
        setClientForm(newClient);
        console.log("client", newClient);
    }, [client]);

    useEffect(() => {
        const fetchTeams = async () => {
            const response = await getTeams();
            if (response.data.success) {
                setTeams(response.data.teams);
                console.log("teams", response.data.teams);
            } else {
                console.log(response.data.message);
            }
        };
        fetchTeams();
    }, []);

    const formatTaxId = (taxNumber) => {
        let inputValue = taxNumber.replace(/\D/g, "");

        if (
            inputValue.length > 2 &&
            client.clientDetail.clientType === "INDIVIDUAL"
        ) {
            inputValue =
                inputValue.substring(0, 2) + "-" + inputValue.substring(2, 9);
        }

        if (inputValue.length > 3 && client.clientDetail.clientType === "BUSINESS") {
            inputValue =
                inputValue.substring(0, 3) +
                "-" +
                inputValue.substring(3, 5) +
                "-" +
                inputValue.substring(5, 9);
        }

        return inputValue;
    };

    const onHandleCancel = () => {
        if (clientForm.teams.length === 0) {
            navigate("/clients");
        } else {
            setOpen(false);
        }
    };

    const onChangeClientName = (e) => {
        setClientForm({ ...clientForm, clientName: e.target.value });
    };

    const onChangeClientType = (e) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                clientType: e.target.value,
            },
        });
    };

    const onChangeClientEmail = (e) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                clientEmail: e.target.value,
            },
        });
    };

    const onChangeClientPhoneNumber = (e) => {
        setClientForm({ ...clientForm, phoneNumber: e });
    };

    const onHandleChangeTaxId = (e) => {
        setClientForm({ ...clientForm, taxNumber: formatTaxId(e.target.value) });
    };

    const onHandleChangeLanguage = (e) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                language: e.target.value,
            },
        });
    };

    const onHandleChangeMethodOfContact = (e) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                contactMethod: e.target.value,
            },
        });
    };

    const onHandleChangeSpouseName = (e) => {
        setClientForm({
            ...client,
            clientDetail: {
                ...client.clientDetail,
                spouseName: e.target.value,
            },
        });
    };

    const onHandleChangeSpouseTaxIdNumber = (e) => {
        setClientForm({
            ...client,
            clientDetail: {
                ...client.clientDetail,
                spouseTaxNumber: e.target.value,
            },
        });
    };

    const onHandleChangeSpouseEmail = (e) => {
        setClientForm({
            ...client,
            clientDetail: {
                ...client.clientDetail,
                spouseEmail: e.target.value,
            },
        });
    };

    const onHandleChangePointOfContactName = (e) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                pointOfContactName: e.target.value,
            },
        });
    };

    const onHandleChangePointOfContactEmail = (e) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                pointOfContactEmail: e.target.value,
            },
        });
    };

    const onHandleChangeSecondaryPointOfContactName = (e) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                secondaryPointOfContactName: e.target.value,
            },
        });
    };

    const onHandleChangeSecondaryPointOfContactEmail = (e) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                secondaryPointOfContactEmail: e.target.value,
            },
        });
    };

    /* const onChangeIsMeetingRequired = (value) => {
        setClientForm({
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
                isMeetingRequired: value === "true",
            },
        });
    }; */

    const onHandleChangeTeams = (e) => {
        setClientForm({ ...clientForm, teams: e });
    };

    const onHandleSubmit = async (e) => {
        e.preventDefault();
        if (clientForm.name === "") {
            toast.error("Name is required");
            return;
        }
        if (clientForm.clientDetail.clientEmail === "") {
            toast.error("Email is required");
            return;
        }
        if (!TAX_NUMBER_REGEX.test(clientForm.taxNumber.replace(/-/g, ""))) {
            toast.error("Tax ID Number has an invalid format");
            return;
        }

        if (clientForm.teams.length === 0) {
            toast.error("Please select at least 1 team");
            return;
        }
        if (clientForm.clientDetail?.clientType === "INDIVIDUAL") {
            clientForm.clientDetail.pointOfContactName = "";
            clientForm.clientDetail.pointOfContactEmail = "";
            clientForm.clientDetail.secondaryPointOfContactName = "";
            clientForm.clientDetail.secondaryPointOfContactEmail = "";
        } else if (clientForm.clientDetail?.clientType === "BUSINESS") {
            clientForm.clientDetail.spouseName = "";
            clientForm.clientDetail.spouseEmail = "";
        }

        const clientToUpdate = {
            ...clientForm,
            clientDetail: {
                ...clientForm.clientDetail,
            },
            address: {
                ...clientForm.address,
            },
            teams: [...clientForm.teams],
            taxNumber: clientForm.taxNumber.replace(/-/g, ""),
        };
        console.log("clientToUpdate", clientToUpdate);
        const res = await updateClient(clientToUpdate);
        console.log(res);

        if (res.success) {
            toast.success(res.message);
            setClient(clientForm);
        } else {
            toast.error(res.message);
        }
        setOpen(false);
    };

    const onClose = () => {
        if (clientForm.manager === null || clientForm.reviewer === null) {
            toast.error("Please select a manager and a reviewer");
        }
    };

    return (
        <AnimatePresence>
            {open && (
                <Dialog
                    initialFocus={completeButtonRef}
                    static
                    as={motion.div}
                    open={open}
                    onClose={() => onClose()}
                    className="relative z-50"
                >
                    {/* The backdrop, rendered as a fixed sibling to the panel container */}
                    <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

                    {/* Full-screen scrollable container */}
                    <div className="fixed inset-0 w-full overflow-y-auto">
                        {/* Container to center the panel */}
                        <div className="flex items-center justify-center min-h-full p-4">
                            <Dialog.Panel className="relative w-screen h-screen px-16 pt-5 pb-4 overflow-y-auto text-left transition-all transform bg-white rounded-lg shadow-xl">
                                <div className="text-center">
                                    <div className="flex justify-between w-full">
                                        <Dialog.Title className="w-full text-lg font-medium leading-6 text-center text-gray-900">
                                            Update Client
                                        </Dialog.Title>
                                    </div>
                                </div>

                                <form onSubmit={onHandleSubmit}>
                                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                        <InputTextModal
                                            required={true}
                                            label="Name *"
                                            name="name"
                                            onChange={(e) => onChangeClientName(e)}
                                            value={clientForm.clientName}
                                        />

                                        <InputTextModal
                                            required={true}
                                            label="Email *"
                                            name="email"
                                            onChange={(e) => onChangeClientEmail(e)}
                                            value={
                                                clientForm.clientDetail.clientEmail
                                            }
                                        />

                                        <SelectInModal
                                            label="Client type"
                                            onChange={(e) => onChangeClientType(e)}
                                            value={
                                                clientForm.clientDetail?.clientType
                                            }
                                            options={CLIENT_TYPES}
                                            required={true}
                                        />

                                        <div className="mt-5 sm:mt-6">
                                            <label
                                                htmlFor="phoneNumber"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Phone number
                                            </label>
                                            <div className="relative inline-block w-full text-left">
                                                <ReactInput
                                                    value={clientForm.phoneNumber}
                                                    onChange={(e) =>
                                                        onChangeClientPhoneNumber(e)
                                                    }
                                                    format={templateFormatter(
                                                        "(xxx) xxx-xxxx"
                                                    )}
                                                    parse={templateParser(
                                                        "(xxx) xxx-xxxx",
                                                        parseDigit
                                                    )}
                                                    displayType="input"
                                                    type="text"
                                                    className="inline-flex justify-between w-full px-3 py-2 text-sm font-normal text-gray-700 truncate bg-white border border-gray-200 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0"
                                                />
                                            </div>
                                        </div>
                                        <InputTextModal
                                            required={true}
                                            label="Tax ID *"
                                            name="taxNumber"
                                            minlength={9}
                                            maxLength={9}
                                            onChange={(e) => onHandleChangeTaxId(e)}
                                            value={clientForm.taxNumber}
                                        />
                                        <SelectInModal
                                            label="Language"
                                            onChange={(e) =>
                                                onHandleChangeLanguage(e)
                                            }
                                            value={clientForm.clientDetail.language}
                                            options={LANGUAGES}
                                            required={true}
                                        />
                                        <SelectInModal
                                            label="Preferred method of contact"
                                            onChange={(e) =>
                                                onHandleChangeMethodOfContact(e)
                                            }
                                            value={
                                                clientForm.clientDetail.contactMethod
                                            }
                                            options={METHODS_OF_CONTACT}
                                            required={true}
                                        />

                                        {/*  <RadioInput
                                            title="Is Meeting Required?"
                                            name="isMeetingRequired"
                                            onChange={onChangeIsMeetingRequired}
                                            items={[
                                                { label: "Yes", value: true },
                                                { label: "No", value: false },
                                            ]}
                                            selectedValue={
                                                clientForm.clientDetail
                                                    .isMeetingRequired
                                            }
                                        /> */}

                                        {clientForm.clientDetail?.clientType ==
                                        "Individual" ? (
                                            <>
                                                <InputTextModal
                                                    label="Spouse Name"
                                                    onChange={(e) =>
                                                        onHandleChangeSpouseName(e)
                                                    }
                                                    value={
                                                        clientForm.clientDetail
                                                            ?.spouseName
                                                    }
                                                />
                                                <InputTextModal
                                                    label="Spouse Tax ID Number"
                                                    onChange={(e) =>
                                                        onHandleChangeSpouseTaxIdNumber(
                                                            e
                                                        )
                                                    }
                                                    value={
                                                        clientForm.clientDetail
                                                            ?.spouseTaxNumber
                                                    }
                                                />
                                                <InputTextModal
                                                    label="Spouse Email"
                                                    onChange={(e) =>
                                                        onHandleChangeSpouseEmail(e)
                                                    }
                                                    value={
                                                        clientForm.clientDetail
                                                            ?.spouseEmail
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <InputTextModal
                                                    label="Name of main point of contact"
                                                    onChange={(e) =>
                                                        onHandleChangePointOfContactName(
                                                            e
                                                        )
                                                    }
                                                    value={
                                                        clientForm.clientDetail
                                                            ?.pointOfContactName
                                                    }
                                                />
                                                <InputTextModal
                                                    label="Email of main point of contact"
                                                    onChange={(e) =>
                                                        onHandleChangePointOfContactEmail(
                                                            e
                                                        )
                                                    }
                                                    value={
                                                        clientForm.clientDetail
                                                            ?.pointOfContactEmail
                                                    }
                                                />
                                                <InputTextModal
                                                    label="Name of secondary point of contact"
                                                    onChange={(e) =>
                                                        onHandleChangeSecondaryPointOfContactName(
                                                            e
                                                        )
                                                    }
                                                    value={
                                                        clientForm.clientDetail
                                                            ?.secondaryPointOfContactName
                                                    }
                                                />
                                                <InputTextModal
                                                    label="Email of secondary point of contact"
                                                    onChange={(e) =>
                                                        onHandleChangeSecondaryPointOfContactEmail(
                                                            e
                                                        )
                                                    }
                                                    value={
                                                        clientForm.clientDetail
                                                            ?.secondaryPointOfContactEmail
                                                    }
                                                />

                                                <AssignTeamsListBox
                                                    title={"Assigned teams"}
                                                    teams={teams}
                                                    assignedTeams={clientForm.teams}
                                                    onChange={onHandleChangeTeams}
                                                />
                                            </>
                                        )}
                                    </div>

                                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                                        <ButtonModal
                                            name="Save"
                                            type="submit"
                                            className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-teal-500 border border-transparent rounded-md shadow-sm hover:bg-teal-600 focus:outline-none focus:ring-0 sm:col-start-2 sm:text-sm"
                                        />
                                        <ButtonModal
                                            name="Cancel"
                                            type="button"
                                            onClick={onHandleCancel}
                                            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 sm:mt-0 sm:col-start-1 sm:text-sm"
                                        />
                                    </div>
                                </form>
                            </Dialog.Panel>
                        </div>
                    </div>
                </Dialog>
            )}
        </AnimatePresence>
    );
};
export default UpdateClientModal;
